import { AxiosError } from 'axios';
import { remove } from 'lodash-es';
import { defineStore, Store } from 'pinia';

import { BaseStoreState } from '../types';

import { LocalStorageEnum } from '@/enums/localStorageEnum';
import { PaymentReturnPathEnum } from '@/enums/paymentEnum';
import { useError } from '@/hooks/useError';
import FormService from '@/services/formService';
import paymentService from '@/services/paymentService';
import { IBasicResult, IDetailReport, IForm, IFormQuestionAnswer, IInformation, ISession } from '@/services/types';
import { store } from '@/store';

// Types
interface FormState extends BaseStoreState {
  checkup: IForm;
  session: ISession;
  basic_result: IBasicResult;
  detail_report: IDetailReport;
  session_exchange_token: string;
}

// Initial State
const InitialFormState: FormState = {
  checkup: {} as IForm,
  session: {} as ISession,
  basic_result: {} as IBasicResult,
  detail_report: {} as IDetailReport,
  session_exchange_token: '',
  errors: []
};

const { getApiErrorResponse } = useError();

export const useFormStore = defineStore('form', {
  state: (): FormState => (InitialFormState),
  actions: {
    async fetchForm(id: string | string[]) {
      try {
        remove(this.errors, (error) => error.method === 'fetchForm');
        const { data } = await FormService.fetchForm(id, this.session.id);
        this.checkup = data.data;
      } catch (error) {
        this.errors.push(getApiErrorResponse(error as AxiosError, 'fetchForm'));
      }
    },
    async createSession(payload: IInformation) {
      try {
        remove(this.errors, (error) => error.method === 'createSession');
        const { data } = await FormService.createSession(payload);
        this.session = await data.data;
        this.session.isFinished = false;
        localStorage.setItem(LocalStorageEnum.Session, JSON.stringify(this.session));
      } catch (error) {
        this.errors.push(getApiErrorResponse(error as AxiosError, 'createSession'));
      }
    },
    async submitAnswers(payload: Array<IFormQuestionAnswer>) {
      try {
        remove(this.errors, (error) => error.method === 'submitAnswers');
        await FormService.submitAnswers(payload, this.session.id);
        this.session.isFinished = true;
        localStorage.setItem(LocalStorageEnum.Session, JSON.stringify(this.session));
      } catch (error) {
        this.errors.push(getApiErrorResponse(error as AxiosError, 'submitAnswers'));
      }
    },
    async fetchBasicResult() {
      try {
        remove(this.errors, (error) => error.method === 'fetchBasicResult');
        const { data } = await FormService.fetchBasicResult(this.session.id);
        this.basic_result = data.data;
      } catch (error) {
        this.errors.push(getApiErrorResponse(error as AxiosError, 'fetchBasicResult'));
      }
    },
    async getSession(session_id: string) {
      try {
        const { data } = await FormService.getSession(session_id);
        this.session = data.data;
      } catch (error) {
        this.errors.push(getApiErrorResponse(error as AxiosError, 'getSession'));
      }
    },
    async detailReport() {
      try {
        const { data } = await FormService.detailReport(this.session.id);
        this.detail_report = data.data;
      } catch (error) {
        this.errors.push(getApiErrorResponse(error as AxiosError, 'detailReport'));
      }
    },
    async sessionTokenExchange() {
      try {
        const { data } = await FormService.sessionTokenExchange(this.session.id);
        this.session_exchange_token = data.data.token;
      } catch (error) {
        this.errors.push(getApiErrorResponse(error as AxiosError, 'sessionTokenExchange'));
      }
    },
    async createPaymentRequest() {
      try {
        const { data } = await paymentService.createPaymentRequest({
          session_id: this.session.id,
          token: this.session_exchange_token,
          success_url: `${location.origin}${PaymentReturnPathEnum.SUCCESS}`,
          fail_url: `${location.origin}${PaymentReturnPathEnum.FAILED}?sid=${this.session.id}`
        });
        window.location.href = data.data.redirectUrl;
      } catch (error) {
        this.errors.push(getApiErrorResponse(error as AxiosError, 'createPaymentRequest'));
      }
    }
  }
});

// Need to be used outside the setup
export function useFormStoreWithoutSetup(): Store {
  return useFormStore(store);
}
