// URLs
import {
  BASIC_RESULT_URL,
  SESSION_URL,
  FORM_URL,
  SUBMIT_ANSWERS_URL,
  DETAIL_REPORT_URL,
  SESSION_TOKEN_EXCHANGE_URL
} from './config';
// Types
import {
  BaseDataResponseModel,
  BaseResponseModel,
  IBasicResult,
  IDetailReport,
  IForm,
  IFormQuestionAnswer,
  IInformation,
  ISession,
  ISessionTokenExchange
} from './types';

import http from '@/plugins/axios';

class FormService {
  fetchForm(id: string | string[], session_id: string): BaseResponseModel<BaseDataResponseModel<IForm>> {
    return http.get(FORM_URL(id), {
      headers: {
        SessionId: session_id
      }
    });
  }

  createSession(payload: IInformation): BaseResponseModel<BaseDataResponseModel<ISession>> {
    return http.post(SESSION_URL, payload);
  }

  submitAnswers(payload: Array<IFormQuestionAnswer>, session_id: string): BaseResponseModel<BaseDataResponseModel<Array<IFormQuestionAnswer>>> {
    return http.post(SUBMIT_ANSWERS_URL, payload, {
      headers: {
        SessionId: session_id
      }
    });
  }

  fetchBasicResult(session_id: string): BaseResponseModel<BaseDataResponseModel<IBasicResult>> {
    return http.get(BASIC_RESULT_URL, {
      headers: {
        SessionId: session_id
      }
    });
  }

  getSession(session_id: string): BaseResponseModel<BaseDataResponseModel<ISession>> {
    return http.get(SESSION_URL, {
      headers: {
        SessionId: session_id
      }
    });
  }

  detailReport(session_id: string): BaseResponseModel<BaseDataResponseModel<IDetailReport>> {
    return http.get(DETAIL_REPORT_URL, {
      headers: {
        SessionId: session_id
      }
    });
  }

  sessionTokenExchange(session_id: string): BaseResponseModel<BaseDataResponseModel<ISessionTokenExchange>> {
    return http.get(SESSION_TOKEN_EXCHANGE_URL, {
      headers: {
        SessionId: session_id
      }
    });
  }
}

export default new FormService();
