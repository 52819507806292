// URLs
import {
  PAYMENT_URL
} from './config';
// Types
import {
  BaseDataResponseModel,
  BaseResponseModel,
  IPayment
} from './types';

import http from '@/plugins/axios';

class FormService {
  createPaymentRequest({ session_id, token, success_url, fail_url }: { session_id: string, token: string, success_url: string, fail_url: string }): BaseResponseModel<BaseDataResponseModel<IPayment>> {
    return http.post(PAYMENT_URL, { EntityId: session_id }, {
      headers: {
        Authentication: token,
        successUrl: success_url,
        cancelUrl: fail_url
      }
    });
  }
}

export default new FormService();
